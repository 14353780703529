<template>
  <div class="page-login-desktop">
    <Table
        label="آگهی های دیوار"
        icon="mdi-format-list-bulleted"
        :table="table">

      <template #header>
        <div class="d-flex align-center justify-end">
          <div style="width: 250px" class="ml-2">
            <v-text-field
                label="جستجو منطقه"
                outlined
                v-model="filters.neighborhood"
                hide-details
                @keydown.enter="fetchItems"
            />
          </div>
          <div style="width: 250px">
            <v-select
                clearable
                @change="fetchItems"
                label="وضعیت فایل ها"
                outlined
                v-model="filters.status"
                hide-details
                item-value="id"
                item-text="name"
                :items="statusOptions"
            />
          </div>
        </div>
      </template>
      <template>
        <tr
            v-for="(item,index) in table.items"
            :key="index"
        >
          <td class="text-center">{{ index + 1 }}</td>
          <td class="text-center">
            <ImageViewer :src="item.image"/>
          </td>
          <td class="text-center">{{ item.created_at }}</td>
          <td class="text-center">{{ item.title }}</td>
          <td class="text-center">{{ item.phoneNumber }}</td>
          <td class="text-center">
            <v-chip
                small
                text-color="white">
              {{ item.status }}
            </v-chip>
          </td>
          <td class="text-center">{{ item.totalAmount }}</td>
          <td class="text-center">{{ item.rentAmount }}</td>
          <td class="text-center">
            <EditButton
                @click="item.actions.edit"
            />
            <v-btn icon fab size="x-small" color="error" title="مشاهده در دیوار" :href="item.source" target="_blank">
              <v-icon>
                mdi-link
              </v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </Table>

    <Pagination
        v-model="filters.skip"
        :key="filters.skip"
        :totalItems="table.totalCount"
        :perPage="filters.take"
        @change="(page)=>{fetchItems({skip: page})}"
    />

    <v-dialog v-model="deleteItem.modal" width="500">
      <v-card>
        <v-card-title class="text-body-1 ">
          <v-icon class="ml-2">
            mdi-information-outline
          </v-icon>
          حذف
        </v-card-title>
        <v-card-text>
          آیا از حذف این فایل مطمئن هستید ؟
        </v-card-text>

        <v-card-actions class="d-flex align-center justify-space-between">
          <v-btn
              @click="deleteItem.modal=false"
              large text color="main">
            لغو
          </v-btn>


          <v-btn
              :loading="deleteItem.loading"
              :disabled="deleteItem.loading"
              @click="destroyItem"
              elevation="0"
              large color="error">
            حذف
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

  </div>
</template>

<script>

import FileDetailsModal from "@/components/FileDetailsModal"
import Table from "@/components/Table"
import ViewButton from "@/components/ViewButton"
import EditButton from "@/components/EditButton"
import MessageButton from "@/components/MessageButton"
import DeleteButton from "@/components/DeleteButton"
import ImageViewer from "@/components/ImageViewer"
import NewMessage from "@/components/NewMessage"
import Pagination from "@/components/Pagination"
import {fetchFilesFromDivar} from "@Newfiling/module-file/src/api";
import {getDataFromUrl, updateUrlQueries, AppConfig} from "@Newfiling/Services";
import moment from "jalali-moment";

export default {
  name: 'DivarFileList',

  components: {
    Table,
    Pagination,
    NewMessage,
    ImageViewer,
    ViewButton,
    EditButton,
    MessageButton,
    DeleteButton,
    FileDetailsModal
  },

  data() {
    return {
      statusOptions: [],
      filters: {
        take: 10,
        skip: 0,
        status: 0,
        neighborhood: null,
      },
      table: {
        headers: [
          '#',
          'عکس',
          'تاریخ ثبت',
          'عنوان',
          'تلفن',
          'وضعیت',
          'قیمت کل / رهن',
          'قیمت متری / اجاره',
          'عملیات',
        ],
        items: [],
        totalCount: 0,
        loading: false,
      },
      deleteItem: {
        modal: false,
        loading: false,
        id: null,
      },
    }
  },

  beforeMount() {
    this.$store.commit('Breadcrumb/setBreadcrumb', [
      {
        text: 'پنل مدیریت نیوفایل',
        disabled: false,
        href: '/panel/dashboard',
      },
      {
        text: 'لیست آگهی های دیوار',
        disabled: true,
        href: '#',
      },
    ])
  },

  mounted() {
    this.$nextTick(async () => {
      this.filters = {
        ...this.filters,
        ...getDataFromUrl(this.$route),
      }
      AppConfig.then((data) => {
        this.statusOptions = [
          ...data?.callcenter.map(i => ({id: i.Id, name: i.Sqlqry1})),
          ...[{id: 0, name: 'نامشخص'}]
        ]
      })
      await this.fetchItems();
    })
  },

  methods: {
    async fetchItems(filters = {}) {
      this.filters = {
        ...this.filters,
        ...filters
      }
      updateUrlQueries(this.$route.path, this.filters);
      this.table.loading = true;
      try {
        const {data, recordcount = 10} = (await fetchFilesFromDivar({
          ...this.filters,
          neighborhood: this.filters?.neighborhood || null,
          skip: this.filters.skip * this.filters.take
        }))?.data

        this.table.items = data.map(item => {
          return {
            ...item,
            id: item.id,
            created_at: moment.from(item.registrationDate, 'YYYY-MM-DDTHH:mm:ss').local('fa').format('jYYYY-jMM-jDD HH:mm'),
            image: item.images1 || '/img/placeholder.png',
            title: `${item.adType} ${item.propertyType} ${item.squareMeters} متری در ${item.areaName}`,
            phoneNumber: item.phoneNumber,
            status: this.statusOptions.find(i => i.id === item.publicationStatus)?.name ?? "نا مشخص",
            actions: {
              edit: () => {
                this.$router.push('/panel/files/divar/create/' + item.id);
              },

              delete: () => {
                this.deleteItem = {
                  ...this.deleteItem,
                  id: item.id,
                  modal: true,
                  loading: false,
                }
              },

              update: () => {

              },
            }
          }
        });
        this.table.totalCount = recordcount;
      } catch (e) {
        console.log(e)
      }
      this.table.loading = false;
    },

    async destroyItem() {
      this.deleteItem.loading = true;
      try {
        // await deleteFile(this.deleteItem.id);
        this.$toast.success('فایل مورد نظر با موفقیت حذف شد.');
        this.deleteItem = {
          ...this.deleteItem,
          modal: false,
          loading: false,
          id: null,
        }
        await this.fetchItems();
      } catch (e) {
        console.log(e)
      }
      this.deleteItem.loading = false;
    },

  }

}
</script>
